@import url("https://fonts.googleapis.com/css2?family=Poppins:200,300,400,500,600,700,800,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  overflow: overlay;
}

section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: url("https://img.freepik.com/free-vector/flat-golden-circle-balloons-birthday-background_52683-34659.jpg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding: 50px;
}

::-webkit-scrollbar {
  width: 12px;
  border-radius: 10px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(#03a9f4, #ff0058);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(#4dff03, #00d0ff);
}

::-webkit-scrollbar-thumb:active {
  background: linear-gradient(#ffbc00, #ff0058);
}

::selection {
  background: linear-gradient(#ffbc00, #ff0058);
  color: cyan;
}

.container {
  position: relative;
  width: calc(100% - 100px);
  min-height: calc(100vh - 280px);
  min-height: 300px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
}

header {
  position: absolute;
  top: -40px;
  right: 20px;
  left: 20px;
  padding: 20px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  text-align: center;
  text-transform: uppercase;
}

header .logo {
  color: #8a2435;
  font-weight: 900;
  text-decoration: none;
  font-size: 1.7em;
  justify-content: center;
  align-items: center;
  text-align: center;
}

header ul {
  display: flex;
}

header ul li {
  list-style: none;
  margin: 0 10px;
}

header ul li a {
  text-decoration: none;
  font-weight: 500;
  color: #8a2435;
  padding: 8px 15px;
  border-radius: 25px;
}

header ul li a:hover,
header ul li a.active {
  background: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

header ul li .login {
  background: #ff9a9e;
  text-decoration: none;
  color: #f6416c;
  border-radius: 50px;
}

header ul li .login:hover {
  background: #f6416c;
  text-decoration: none;
  color: #4afff0;
}

.content {
  transform: translateX(-100px);
  max-width: 700px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  padding: 40px;
  padding-left: 110px;
}

.content h2 {
  font-size: 3em;
  color: #8a2435;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-weight: 900;
  margin-left: -20px;
}

.content p {
  font-size: 1.2em;
  color: #8a2435;
  text-transform: capitalize;
  /* font-weight: 600; */
}

.content a {
  display: inline-block;
  padding: 15px 30px;
  background: #fff;
  color: #8a2435;
  margin-top: 20px;
  border: radius 30px;
  font-size: 1.2em;
  font-weight: 500;
  text-decoration: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}

.imgBx img {
  max-width: 400px;
  animation: animate 4s linear infinite;
  height: 350px;
  border-radius: 80px;
}

@keyframes animate {
  0%,
  100% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(53px);
  }
}

.sci {
  position: absolute;
  right: -25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.sci li {
  list-style: none;
}

.sci li a {
  text-decoration: none;
  width: 50px;
  height: 50px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 50px 0;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
}

.sci li a img {
  transform: scale(0.6);
  transition: 0.5s;
}

.sci li:hover a img {
  transform: scale(0.6) rotate(360deg);
}

.copyrightText {
  position: absolute;
  bottom: 8px;
  left: 80px;
  color: #8a2435;
  margin-top: 40px;
  font-weight: 600;
  font-size: 1.7rem;
}

@media (max-width: 1200px) {
  .container {
    position: relative;
    width: calc(100% - 20px);
  }
}

@media (max-width: 992px) {
  section {
    padding: 50px 10px 10px;
  }
  .container {
    flex-direction: column;
    height: auto;
    padding: 20px;
  }
  .container .content {
    transform: translateX(0px);
    max-width: 100%;
    padding: 30px;
    margin-top: 40px;
  }
  header {
    position: relative;
    flex-direction: column;
    width: 100%;
    top: -60px;
    padding: 20px;
    left: 0;
    right: 0;
  }
  header ul {
    margin-top: 10px;
    flex-wrap: wrap;
    justify-content: center;
  }
  header ul li {
    margin: 2px;
  }
  header ul li a {
    display: inline-block;
  }
  .imgBx {
    margin-bottom: 50px;
  }
  .content a {
    font-size: 1em;
    padding: 10px 20px;
  }
  @keyframes animate {
    0%,
    100% {
      transform: translateY(-20px);
    }
    50% {
      transform: translateY(20px);
    }
  }
  .sci {
    position: relative;
    flex-direction: row;
    left: 0;
  }
  .sci li a {
    margin: 0 10px;
  }
  .copyrightText {
    position: relative;
    bottom: initial;
    left: inherit;
    width: 100%;
    color: #8a2435;
    text-align: center;
    margin-top: 20px;
  }
}

@media (max-width: 600px) {
  .content h2 {
    /* font-size: 1em; */
    color: #8a2435;
  }
  .content p {
    /* font-size: 1em; */
    color: #8a2435;
  }
  .imgBx img {
    max-width: 300px;
  }
}
@media (max-width: 350px) {
  .content h2 {
    font-size: 1.4em;
  }
}
